import React, { ChangeEvent } from 'react';

import { LinkButton } from 'components/v2/Buttons/LinkButton/LinkButton';
import styled from 'styled-components';

import { InputWrapper as InputWrapperBase, TextInputStyled } from '../../styles';

type Props = {
  value: string;
  error: boolean | null;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
  className?: string;
  hasEmployer?: boolean;
};

export const PromoCode = (props: Props) => {
  const { value, error, onChange, onSubmit, className, hasEmployer = false } = props;
  const label = hasEmployer ? 'ACCESS CODE' : 'Promo code (Optional)';
  const placeholder = hasEmployer ? 'Required' : 'Enter promo code...';

  return (
    <PromoCodeWrapper className={className}>
      <InputWrapper>
        <TextInputStyled
          inputId="promo-code"
          label={label}
          placeholder={placeholder}
          value={value}
          error={error || undefined}
          onChange={onChange}
        />
      </InputWrapper>
      <PromoLink onClick={onSubmit} text="APPLY" />
    </PromoCodeWrapper>
  );
};

const PromoCodeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  background: white;
`;

const PromoLink = styled(LinkButton).attrs({ fontStyle: 'semibold' })``;

const InputWrapper = styled(InputWrapperBase)`
  margin: 0;
  margin-right: 5px;
`;
