import { Pin } from 'assets/icons/Icons';
import { Text } from 'components/v2/Typography';
import styled from 'styled-components';

export const Location = styled(Text).attrs({ tag: 'p' })`
  margin-top: 16px;
  word-break: break-word;
  justify-content: center;
  text-align: center;
`;

export const PinIcon = styled(Pin)`
  margin-right: 8px;
`;
