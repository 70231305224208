import React from 'react';

import Button from 'components/Buttons/NewButton';
import { Membership } from 'kb-shared';

import { ConfirmDetailsWrapper } from '../styles';
import { Container, Content, Price, AcessCodeHelper } from './ConfirmMembershipDetails.styled';
import { MembershipDetailBlock } from './MembershipDetailBlock';
import { TermsDetails } from './TermsDetails';

type Props = {
  loading: boolean;
  membership: Membership | null;
  termsAccepted: boolean;
  discountPrice: number | null;
  hasEmployer?: boolean;
  validatedPromoCode?: boolean;
  onTermsAcceptToggled: () => void;
};

export const ConfirmMembershipDetails = (props: Props) => {
  const {
    loading = false,
    membership = null,
    termsAccepted = false,
    discountPrice = null,
    onTermsAcceptToggled = () => null,
    hasEmployer = false,
    validatedPromoCode = false
  } = props;
  if (!membership) return null;
  // special case for free membership promo because 0 is falsey
  const priceString =
    discountPrice === 0
      ? 'Free of charge'
      : `$${(discountPrice || membership.rateCents) / 100} billed yearly`;
  const accessCodeString = validatedPromoCode
    ? 'Membership unlocked'
    : 'Please enter activation code above.';
  return (
    <ConfirmDetailsWrapper>
      <Container>
        <Content>
          <MembershipDetailBlock hasEmployer={hasEmployer} />
          {hasEmployer && <AcessCodeHelper>{accessCodeString}</AcessCodeHelper>}
          {!hasEmployer && <Price>{priceString}</Price>}
          <TermsDetails selected={termsAccepted} onCheckBoxSelected={onTermsAcceptToggled} />
          <Button
            loading={loading}
            disabled={loading || !termsAccepted || (hasEmployer && !validatedPromoCode)}
            type="submit"
            text="Confirm & Book"
          />
        </Content>
      </Container>
    </ConfirmDetailsWrapper>
  );
};
