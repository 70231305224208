import React, { ReactNode } from 'react';

import LeftBracket from 'components/SVG/LeftBracket';
import RightBracket from 'components/SVG/RightBracket';
import { themes } from 'kb-shared';
import styled from 'styled-components';

type Props = {
  title: string;
  value: string;
  subtitle: string | ReactNode;
  link?: { text: string; onClick: Function };
};

export class Result extends React.Component<Props> {
  render() {
    const { title, value, subtitle, link } = this.props;
    return (
      <Container>
        <TitleText>{title}</TitleText>
        <BracketContainer>
          <LeftBracket color={themes.colors.neutral.lavender} />
          <TextContainer>
            <ValueText>{value}</ValueText>
            <SubtitleText>{subtitle}</SubtitleText>
          </TextContainer>
          <RightBracket color={themes.colors.neutral.lavender} />
        </BracketContainer>
        <Link>{link && link.text}</Link>
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`;

const BracketContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 10px 0px 10px;
`;

const TitleText = styled.div`
  font-size: 24px;
  color: ${themes.colors.yellow.primary};
  margin-bottom: 18px;
`;

const ValueText = styled.div`
  font-size: 48px;
  color: white;
`;

const SubtitleText = styled.div`
  font-size: 14px;
  color: white;
`;

const Link = styled.div`
  font-size: 14px;
  text-decoration: underline;
  color: white;
  margin-top: 12px;
  cursor: pointer;
`;
