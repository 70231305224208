import React from 'react';
import { useLocation } from 'react-router-dom';

import { YellowButton } from 'components/Buttons/Buttons';

import { membership } from '../assets/icons';
import { Card, Title, Description } from './MembershipConfirmationCard.styled';

interface Props {
  id?: string;
  icon?: string;
  title?: string;
  description?: string;
  cta?: string;
  onClick: () => void;
}

const DEFAULT_TITLE = 'Kindbody Member';

export const MembershipConfirmationCard = ({ onClick }: Props) => {
  const location = useLocation();
  const title = location.state?.title || DEFAULT_TITLE;

  return (
    <Card>
      <img src={membership} alt="" />
      <Title>{title}</Title>
      <Description>Ready to take advantage of your membership benefits?</Description>
      <YellowButton onClick={onClick}>Book an appointment</YellowButton>
    </Card>
  );
};
