import { Heading } from 'components/v2/Typography';
import { Text } from 'components/v2/Typography/Text';
import theme from 'kb-shared/theme';
import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 80px 16px 16px 16px;
  overflow-y: auto;
`;

export const Empty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 16px 32px 16px;
  flex: 1;
  justify-content: center;
`;

export const Title = styled(Heading).attrs({ tag: 'h2' })`
  margin: 32px 0 0 0;
`;

export const Description = styled(Heading).attrs({ tag: 'h5', styledAS: 'h5' })`
  padding: 16px 0;
  max-width: 350px;
  text-align: center;
`;

export const EmergencyContact = styled(Text).attrs({ size: 'sm' })`
  display: flex;
  padding: 8px 16px;
  gap: 12px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.neutral.lavenderLight};
  box-shadow: 0px 0.48px 0px 0px ${({ theme }) => theme.colors.lilac.lighterLilac},
    0px 1px 1px 0px rgba(10, 13, 61, 0.08);
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  align-items: center;

  a {
    font-weight: 800;
  }
`;

export const Circle = styled.div`
  width: 120px;
  height: 120px;
  background: #fff;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lavender};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

export const Icon = styled.div`
  svg {
    width: 60px;
    height: 60px;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const ConversationInfo = styled(Text).attrs({
  size: 'sm',
  fontStyle: 'medium',
  color: theme.colors.green.darkestGreen
})`
  background: ${({ theme }) => theme.colors.green.lightestGreen};
  padding: 12px 16px;
  text-align: center;
  margin: 8px 0 0 0;
  border-radius: 8px;
  box-shadow: 0px 0.48px 0px 0px ${({ theme }) => theme.colors.green.green},
    0px 1px 1px 0px rgba(54, 121, 30, 0.24);
`;
