import React from 'react';

import Initials from 'components/Initials';
import { Text } from 'components/v2/Typography';
import theme from 'kb-shared/theme';

import { CareTeamPeople } from '../CareTeam.types';
import { DetailsWrapper } from '../styled';
import { Name } from './AssignedCareTeamPeople.styled';

export const AssignedCareTeamPeople = ({ careTeam }: { careTeam?: CareTeamPeople }) => {
  if ((!careTeam?.doctor && !careTeam?.clinicNurse && !careTeam?.cloudNurse) || !careTeam) {
    return null;
  }

  return (
    <>
      <Person responsibility="Provider of Record" name={careTeam.doctor} />
      <Person responsibility="Advance Practice Provider" name={careTeam.advancedPracticeProvider} />
      <Person
        responsibility="Patient Experience Navigator of Record"
        name={careTeam.patientExperienceNavigator}
      />
      <Person responsibility="Care Coordinator of Record" name={careTeam.clinicNurse} />
      <Person responsibility="Financial Navigator of Record" name={careTeam.financialNavigator} />
      <Person responsibility="Cloud Nurse" name={careTeam.cloudNurse} />
    </>
  );
};

const Person = ({ name, responsibility }: { name?: string; responsibility: string }) => {
  if (name == null) {
    return null;
  }

  return (
    <DetailsWrapper>
      <Text size="sm" color={theme.colors.neutral.navy} fontStyle="medium">
        {responsibility}
      </Text>
      <Name>
        <Initials name={name} size="md" />
        <Text size="md" color={theme.colors.neutral.navy}>
          {name}
        </Text>
      </Name>
    </DetailsWrapper>
  );
};
