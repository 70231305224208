import { useQuery } from '@apollo/client';
import React, { useState } from 'react';

import { usePromo } from 'hooks/usePromo';
import {
  Appointment,
  AppointmentProduct,
  AppointmentType,
  PatientMembership,
  ValidPromo
} from 'kb-shared';
import { BugTracker } from 'kb-shared/utilities/bugTracker';

import { PATIENT_MEMBERSHIP } from './AppointmentSummaryDetails.graphql';
import { ConfirmAppointmentDetails } from './ConfirmationAppointmentDetails';

interface Props {
  appointment: AppointmentType | Appointment | null;
  product: AppointmentProduct<AppointmentType>;
  loading?: boolean;
  disabled?: boolean;
  paymentTermsChecked: boolean;
  onPaymentTermsChecked: (checked: boolean) => void;
  onValidPromoChange: (validPromo: ValidPromo | null) => void;
}

export const AppointmentSummaryDetails = (props: Props) => {
  const {
    appointment,
    paymentTermsChecked,
    onPaymentTermsChecked,
    onValidPromoChange,
    loading = false,
    disabled = false,
    product
  } = props;

  const [membershipPromoCode, setMembershipPromoCode] = useState<string | null>(null);
  const { validatePromo } = usePromo(product);

  useQuery<PatientMembership>(PATIENT_MEMBERSHIP, {
    skip: !!membershipPromoCode,
    onCompleted: data => {
      if (membershipPromoCode) return;
      const firstRule = data?.patient?.patientMembership?.membershipRules?.[0];
      if (!firstRule) return;

      const discountCode = firstRule.code;

      setMembershipPromoCode(discountCode);
      validatePromo(discountCode)
        .then(({ validPromo }) => {
          onValidPromoChange(validPromo || null);
        })
        .catch(error =>
          BugTracker.notify(error, 'Validate Promo Code during Payment Confirmation')
        );
    },
    onError: error => BugTracker.notify(error, 'AppointmentSummaryDetails')
  });

  return (
    <ConfirmAppointmentDetails
      loading={loading}
      disabled={disabled}
      appointment={appointment}
      termsAccepted={paymentTermsChecked}
      onTermsAcceptToggled={() => onPaymentTermsChecked(!paymentTermsChecked)}
    />
  );
};
