import { Heading, Text } from 'components/v2/Typography';
import { PromoCode as PromoCodeBase } from 'screens/Book/components/PromoCode/PromoCode';
import styled from 'styled-components';

export const PaymentFormContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: 30px;
  width: 100%;
`;

export const PromoCode = styled(PromoCodeBase)`
  width: 100%;
  flex: 0 0 auto;
  margin-top: 15px;
  text-align: left;
  justify-content: flex-start;
`;

export const AccessCodeTitle = styled(Heading).attrs({
  tag: 'h2',
  styledAs: 'h5',
  noMargin: true,
  flexDirection: 'row'
})`
  gap: 8px;
`;

export const AccessCodeDetails = styled(Text).attrs({ size: 'md' })``;

export const AccessCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
