import { gql } from '@apollo/client';

export const FRAGMENT_APPOINTMENT_FIELDS = gql`
  fragment AppointmentFields on Appointment {
    id
    videoLink
    location {
      id
      address
      name
      patientFacing
      timeZone
      lab {
        id
      }
    }
    startTime
    endTime
    timeZone
    cancelable
    reschedulable
    appointmentType {
      id
      virtual
      name
      description
      videoLink
      rateCents
      channel
      category {
        id
        name
      }
    }
  }
`;

export const CREATE_APPOINTMENT = gql`
  mutation createAppointment(
    $timeSlotId: Int!
    $appointmentTypeId: Int!
    $stripeTokenId: String
    $patientPackageId: Int
    $packageItemId: Int
    $discountCode: String
    $kbStripeCardStripeIdentifier: String
    $providerId: Int
  ) {
    createAppointment(
      timeSlotId: $timeSlotId
      appointmentTypeId: $appointmentTypeId
      stripeTokenId: $stripeTokenId
      patientPackageId: $patientPackageId
      packageItemId: $packageItemId
      discountCode: $discountCode
      kbStripeCardStripeIdentifier: $kbStripeCardStripeIdentifier
      providerId: $providerId
    ) {
      appointment {
        ...AppointmentFields
      }
    }
  }
  ${FRAGMENT_APPOINTMENT_FIELDS}
`;
