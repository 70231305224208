import { History } from 'history';
import React, { MouseEvent } from 'react';

import { Button } from 'components/Buttons/Button';
import { themes } from 'kb-shared';
import styled from 'styled-components';
import { pageUrl } from 'utilities/pageUrl';

import { CardButtonGroup, CardHeader, CardTitle, ConfirmationCard, DashboardCard } from './Card';
import { LinkButton } from './v2/Buttons/LinkButton/LinkButton';
const { colors } = themes;

const ConfirmCard = styled(ConfirmationCard)`
  background: ${colors.yellow.lighterYellow};
  width: initial;
  max-width: initial;
`;

const DashCard = styled(DashboardCard)`
  background: ${colors.yellow.lighterYellow};
`;

const Text = styled.div`
  margin-bottom: 20px;

  font-size: 17px;
`;

interface ButtonGroupProps {
  anchorButtons?: boolean;
}

const ButtonGroup = styled(CardButtonGroup)`
  justify-content: ${(p: ButtonGroupProps) => (p.anchorButtons ? 'flex-end' : 'initial')};
  margin-top: ${(p: ButtonGroupProps) => (p.anchorButtons ? 0 : '20px')};
  padding-bottom: 9px;

  button {
    text-transform: uppercase;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

type Props = {
  confirmation?: boolean;
  history: History;
};

export default function IntakeFormCard(props: Props) {
  const { confirmation, history } = props;
  const goToIntakeForm = () => {
    window.location.href = pageUrl.intakeForm();
  };
  const goToDashboard = (event: MouseEvent) => {
    event.preventDefault();
    history.push('/');
  };

  const Wrapper = confirmation ? ConfirmCard : DashCard;
  return (
    <Wrapper>
      <Content>
        <CardHeader confirmation={confirmation}>Next step:</CardHeader>
        <CardTitle confirmation={confirmation}>
          Complete your <br />
          intake form
        </CardTitle>
        <Text>Your intake form should be completed 48 hours prior to your first visit.</Text>
        <ButtonGroup anchorButtons={confirmation}>
          <Button type="submit" label="Fill out intake form" onClick={goToIntakeForm} />
        </ButtonGroup>
        {confirmation && <LinkButton text="Go To Dashboard" onClick={goToDashboard} size="sm" />}
      </Content>
    </Wrapper>
  );
}
