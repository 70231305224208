import { themes } from 'kb-shared';
import styled from 'styled-components';

const { colors } = themes;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${colors.neutral.primaryNavy};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
`;

export const Price = styled.div`
  font-size: 36px;
  text-align: center;
  color: white;
  padding-bottom: 20px;
`;

export const AcessCodeHelper = styled(Price)``;
