import Honeybadger from '@honeybadger-io/js';
import React, { useEffect, useState } from 'react';

import { ReactComponent as SecureLockIcon } from 'assets/icons/secure-lock.svg';
import { StripeContext } from 'components/CreditCardForm/types/creditCardForm';
import { usePromo } from 'hooks/usePromo';
import { AppointmentProduct, AppointmentType, Membership, ValidPromo } from 'kb-shared';
import { ConfirmDetails } from 'screens/Book/components/ConfirmDetails';
import { PaymentDetails } from 'screens/Invoices/components/InvoicePayModal/PaymentDetails';
import { analytics } from 'utilities/analytics';
import { showErrorToast, showSuccessToast } from 'utilities/notificationUtils';

import {
  PaymentFormContainer,
  PromoCode,
  AccessCodeContainer,
  AccessCodeDetails,
  AccessCodeTitle
} from './PaymentForm.styled';

interface Props {
  appointment: AppointmentType | Membership;
  loading?: boolean;
  noPayment?: () => void;
  onPaymentOptional?: () => void;
  onPaymentTermsChecked: (checked: boolean) => void;
  onSubmit: (stripeTokenId?: string, kbStripeCardStripeIdentifier?: string) => void;
  onValidPromoChange: (validPromo: ValidPromo | null) => void;
  paymentTermsChecked?: boolean;
  product: AppointmentProduct<AppointmentType>;
  hasEmployer?: boolean;
  validPromo: ValidPromo | null;
}

export const PaymentForm = (props: Props) => {
  const {
    appointment,
    noPayment,
    onPaymentOptional,
    onPaymentTermsChecked,
    onSubmit,
    onValidPromoChange,
    paymentTermsChecked,
    product,
    loading = false,
    hasEmployer = false,
    validPromo
  } = props;
  const [promoCode, setPromoCode] = useState<string | null>(null);
  const [kbStripeCardStripeIdentifierSelected, setKbStripeCardStripeIdentifier] = useState('');
  const [promoError, setPromoError] = useState(false);
  const [inputValid, setInputValid] = useState(false);
  const [loadingStripeToken, setLoadingStripeToken] = useState(false);
  const { validatePromo } = usePromo(product);
  const [stripeContext, setStripeContext] = useState<StripeContext | undefined>(undefined);
  const [creditCardFieldEmpty, setCreditCardFieldEmpty] = useState(true);
  const isDisabled = () => {
    if (noPayment || onPaymentOptional) {
      return loading;
    }

    return loading || !inputValid || (!stripeContext && !kbStripeCardStripeIdentifierSelected);
  };

  useEffect(() => {
    analytics.track(analytics.EVENTS.PATIENT_PORTAL_BOOKING_APPOINTMENT_PAYMENT_LOADED);
  }, []);

  return (
    <form
      onSubmit={async e => {
        e.preventDefault();
        if (noPayment) return noPayment();

        // if payment is optional and there is no card info, submit without card info
        // otherwise, go through the normal flow(validate card, get token, submit)
        if (onPaymentOptional && !kbStripeCardStripeIdentifierSelected && creditCardFieldEmpty) {
          return onPaymentOptional();
        }

        if (stripeContext) {
          setLoadingStripeToken(true);
          const tokenResponse = await stripeContext.stripe.createToken(
            stripeContext.cardNumberElement
          );
          setLoadingStripeToken(false);
          if (tokenResponse.error) {
            const tokenResponseError = tokenResponse.error.message;
            if (tokenResponseError) {
              showErrorToast(tokenResponseError);
              Honeybadger.notify(tokenResponseError);
            }
            return;
          }
          const stripeTokenId = tokenResponse.token?.id;
          onSubmit(stripeTokenId);
          return;
        }

        if (kbStripeCardStripeIdentifierSelected) {
          onSubmit(undefined, kbStripeCardStripeIdentifierSelected);
        }
      }}
    >
      {(!noPayment || hasEmployer) && (
        <PaymentFormContainer>
          {!hasEmployer && (
            <PaymentDetails
              paymentOptional={Boolean(onPaymentOptional)}
              onSelectCreditCard={data => setKbStripeCardStripeIdentifier(data)}
              onStripeCardElementInitialized={(stripe, cardNumberElement) => {
                setStripeContext({
                  stripe,
                  cardNumberElement
                });
              }}
              onValidation={valid => setInputValid(valid)}
              onCreditCardChange={({ empty }) => setCreditCardFieldEmpty(empty)}
            />
          )}

          {hasEmployer && (
            <AccessCodeContainer>
              <AccessCodeTitle>
                <span>Enter Your Access Code</span> <SecureLockIcon />
              </AccessCodeTitle>
              <AccessCodeDetails>
                Your benefits provider has given you an access code in order to unlock your Kindbody
                membership. Please enter it here.
              </AccessCodeDetails>
            </AccessCodeContainer>
          )}

          <PromoCode
            value={promoCode || ''}
            error={promoError !== null}
            hasEmployer={hasEmployer}
            onChange={event => {
              setPromoCode(event.target.value);
            }}
            onSubmit={() => {
              setPromoError(false);
              validatePromo(promoCode)
                .then(({ validPromo, error }) => {
                  onValidPromoChange(validPromo || null);
                  if (error) {
                    setPromoError(true);
                    analytics.track(analytics.EVENTS.PROMO_CODE_FAILED, {
                      promo_code: promoCode
                    });
                    return showErrorToast(error);
                  }
                  showSuccessToast('Promo code accepted.');
                  analytics.track(analytics.EVENTS.PROMO_CODE_SUCCEEDED, {
                    promo_code: promoCode
                  });
                })
                .catch(error => Honeybadger.notify(error));
            }}
          />
        </PaymentFormContainer>
      )}
      <ConfirmDetails
        appointment={appointment}
        product={product}
        loading={loading || loadingStripeToken}
        disabled={isDisabled()}
        paymentTermsChecked={paymentTermsChecked}
        validPromo={validPromo}
        hasEmployer={hasEmployer}
        onPaymentTermsChecked={onPaymentTermsChecked}
        onValidPromoChange={onValidPromoChange}
      />
    </form>
  );
};
