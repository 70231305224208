import * as React from 'react';

import { YellowButton } from 'components/Buttons/Buttons';
import { Result as ResultsWidget } from 'components/Dashboard/ResultsWidget/Result';
import { utils } from 'kb-shared';
import styled from 'styled-components';

const { AppointmentTypeIds } = utils;

interface Props {
  resultValue: number | null;
}

const ResultDiv = (props: Props) => {
  const appointmentTypeId = AppointmentTypeIds.FOLLOW_UP;

  return (
    <Div>
      <p>Total number of eggs to freeze</p>
      <ResultsWidget title="" value={`${props.resultValue || '0'}`} subtitle="eggs" />
      <p>Chat with us about how to best plan for your future family</p>
      {
        // @ts-ignore
        <Button as="a" href={`/book?step=location&appointment_id=${appointmentTypeId}`}>
          Schedule a call
        </Button>
      }
    </Div>
  );
};

export default ResultDiv;

const Div = styled.div`
  ${({ theme }) => theme.grid.container};
  background-color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  padding-top: 10px;
  padding-bottom: 30px;

  p {
    ${({ theme }) => theme.typos.bodyText};
    color: ${({ theme }) => theme.colors.white};
    text-align: center;
  }
`;

const Button = styled(YellowButton)`
  display: block;
  margin: 15px auto 0;
  text-align: center;
`;
